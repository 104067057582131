// Selector to get the entire dashboard state
export const selectDashboardState = (state) => state.dashboard;

// Selector to get dashboard statistics
export const selectDashboardStatistics = (state) => state.dashboard.statistics;

// Selector to get revenue data
export const selectRevenueData = (state) => state.dashboard.revenue;

// Selector to get the loading state
export const selectDashboardLoading = (state) => state.dashboard.loading;

// Selector to get the error state
export const selectDashboardError = (state) => state.dashboard.error;
