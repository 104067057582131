import React, { useState } from "react";
import ServicesModal from "../../components/Modal/ServicesModal/ServicesModal";

const ServicesModalEditBtn = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  return (
    <>
      <button className="edit-button" onClick={showDrawer}>
        Edit
      </button>
      <ServicesModal open={open} onClose={onClose} />
    </>
  );
};

const AlertsModalEditBtn = () => {
  return (
    <>
      <button className="edit-button">Stop alert</button>
    </>
  );
};

const EditButton = ({ modal }) => {
  let Btn;
  switch (modal) {
    case "servicesModal":
      Btn = ServicesModalEditBtn;
      break;
    case "alertsModal":
      Btn = AlertsModalEditBtn;
      break;
    default:
      Btn = ServicesModalEditBtn;
  }
  return <Btn />;
};

export default EditButton;
