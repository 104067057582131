// Selector to get the entire transactions state
export const selectTransactionsState = (state) => state.transaction;

// Selector to get the transaction list data
export const selectTransactionList = (state) => state.transaction?.transactions?.data ?? [];

// Selector to get pagination information (current page, total pages, page size)
export const selectTransactionPagination = (state) => ({
  currentPage: state.transaction.currentPage,
  totalPages: state.transaction.totalPages,
  pageSize: state.transaction.pageSize,
});

// Selector to get the loading state for general transactions actions (list, search, filter)
export const selectTransactionsLoading = (state) => state.transaction.loading;

// Selector to get the general error state for transactions actions
export const selectTransactionsError = (state) => state.transaction.error;

// Selector to get the export state (loading, success, error)
export const selectExportState = (state) => state.transaction.export;

// Selector to get the loading state for export
export const selectExportLoading = (state) => state.transaction.export.loading;

// Selector to get the success state for export
export const selectExportSuccess = (state) => state.transaction.export.success;

// Selector to get the export error
export const selectExportError = (state) => state.transaction.export.error;

// Selector to get the transaction details state
export const selectTransactionDetails = (state) => state.transaction.getTransactionDetails.data;

// Selector to get the loading state for fetching transaction details
export const selectTransactionDetailsLoading = (state) => state.transaction.getTransactionDetails.loading;

// Selector to get the error state for fetching transaction details
export const selectTransactionDetailsError = (state) => state.transaction.getTransactionDetails.error;
