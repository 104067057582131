import { createPromiseAction } from '@adobe/redux-saga-promise';

// Actions for listing users
export const listUsers = createPromiseAction('users/list');

// Actions for filtering users
export const filterUsers = createPromiseAction('users/filter');

// Actions for searching users
export const searchUsers = createPromiseAction('users/search');

// Actions for exporting users
export const exportUsers = createPromiseAction('users/export');

// Actions for blocking users
export const blockUser = createPromiseAction('users/block');

// Actions for unblocking users
export const unblockUser = createPromiseAction('users/unblock');

// Actions for deleting users
export const deleteUser = createPromiseAction('users/delete');

// Actions for updating user details
export const updateUser = createPromiseAction('users/update');

// Actions for getting user info
export const getUserInfo = createPromiseAction('users/getInfo');

// Actions for updating wallet balance
export const updateWalletBalance = createPromiseAction('users/updateWalletBalance');

// Actions for updating user commission
export const updateUserCommission = createPromiseAction('users/updateCommission');

// Actions for listing user commissions
export const listUserCommissions = createPromiseAction('users/listCommissions');

// Actions for getting user transaction trace
export const getUserTransactionTrace = createPromiseAction('users/getTransactionTrace');
