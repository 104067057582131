// sagas/verificationSaga.js

import { call, all, takeEvery } from 'redux-saga/effects';
import { sendOtpRequest, verifyOtpRequest } from '../../api/verification'; // API imports
import { sendOtp, verifyOtp } from '../actions/verification'; // Promise actions
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { translateErrorResponse } from '../../utils/utils'; // Utility functions

// Saga to handle sending OTP
export function* handleSendOtp(action) {
  try {
    const response = yield call(sendOtpRequest, action.payload);

    // Resolve the promise action with the response data
    yield call(resolvePromiseAction, action, response?.data ?? {});

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;

    // Reject the promise action with the error message
    yield call(rejectPromiseAction, action, errorMessage);
  }
}

// Saga to handle verifying OTP
export function* handleVerifyOtp(action) {
  try {
    const response = yield call(verifyOtpRequest, action.payload);

    // Resolve the promise action with the response data
    yield call(resolvePromiseAction, action, response?.data ?? {});

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;

    // Reject the promise action with the error message
    yield call(rejectPromiseAction, action, errorMessage);
  }
}

// Root saga to watch for OTP actions
export default function* verificationSaga() {
  yield all([
    takeEvery(sendOtp.trigger, handleSendOtp), // Watch for send OTP action
    takeEvery(verifyOtp.trigger, handleVerifyOtp), // Watch for verify OTP action
  ]);
}
