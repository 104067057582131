import { createPromiseAction } from '@adobe/redux-saga-promise';

// Actions for listing providers
export const listProviders = createPromiseAction('providers/list');

// Actions for searching providers
export const searchProviders = createPromiseAction('providers/search');

// Actions for filtering providers
export const filterProviders = createPromiseAction('providers/filter');

// Actions for exporting providers
export const exportProviders = createPromiseAction('providers/export');

// Actions for viewing provider details
export const getProviderInfo = createPromiseAction('providers/info');

// Actions for updating provider info
export const updateProviderInfo = createPromiseAction('providers/updateInfo');

// Actions for updating provider balance
export const updateProviderBalance = createPromiseAction('providers/updateBalance');

// Actions for adding a provider
export const addProvider = createPromiseAction('providers/add');
