import { Card, Form, Input } from "antd";
import React from "react";
import SapaButton from "../../ui/Button/Button";
import SapaHeader from "../../ui/Header/Header";

const Security = () => {
  return (
    <div className="Security">
      <SapaHeader page={"Security"} />
      <Card className="sapa-security__container">
        <p>Change password</p>
        <Form name="basic" layout="vertical" autoComplete="off">
          <Form.Item
            label={<label>Old password</label>}
            name="Old password"
            className="sapa-security__inputWrapper"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input.Password
              className="sapa-security__password"
              placeholder="********"
            />
          </Form.Item>
          <Form.Item
            label={<label>New password</label>}
            name="New password"
            className="sapa-security__inputWrapper"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input.Password
              className="sapa-security__password"
              placeholder="********"
            />
          </Form.Item>
          <Form.Item
            label={<label>Repeat password</label>}
            name="Repeat password"
            className="sapa-security__inputWrapper"
            rules={[{ required: true }]}
            hasFeedback
          >
            <Input.Password
              className="sapa-security__password"
              placeholder="********"
            />
          </Form.Item>
          <Form.Item>
            <SapaButton
              label={"Access account"}
              customClass="sapa-security__button"
              shape="rounded"
              buttonType="primary"
            />
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default Security;
