// api/dashboard.js

import apiClient from './apiClient';

// Fetch dashboard statistics
export const fetchDashboardStatisticsRequest = () =>
  apiClient({
    method: 'GET',
    url: '/dashboard/statistics',
  });

// Fetch revenue data
export const fetchRevenueDataRequest = () =>
  apiClient({
    method: 'GET',
    url: '/dashboard/revenue',
  });
