import { Avatar } from "antd";
import Paragraph from "antd/es/typography/Paragraph";
import React from "react";
import EEDC from "../../../../assets/logo/services/EEDC.png";
import CheckCircle from "../../../../assets/svgs/svg-icons/Check-circle";
import CopyIcon from "../../../../assets/svgs/svg-icons/Copy-icon";
import SapaButton from "../../../../ui/Button/Button";

const TransactionDetails = () => {
  return (
    <>
      <Avatar className="modal-avatar" src={EEDC} alt="/" />
      <div className="transaction-modal-list__container">
        <div>
        <span>
          <p>Date & Time</p>
          <p className="modal-list-dark-text">3 DEC,2022; 10:58</p>
        </span>
        <span>
          <p>Beneficiary</p>
          <div className="modal-list-long">
            <pre className="modal-list-dark-text">12345678910</pre>
            <pre>OHIAERI KENECHUKWU</pre>
            <pre>FIRST BANK NIGERIA</pre>
          </div>
        </span>
        <span>
          <p>Token</p>
          <Paragraph
          className="modal-list-dark-text"
            copyable={{
              icon: [
                <CopyIcon key="copy-icon" />,
                <CheckCircle key="copied-icon" />,
              ],
              text: "4854 9324 5933",
            }}
          >
            4854 9324 5933
          </Paragraph>
        </span>
        <span>
          <p>Reference</p>
          <p className="modal-list-dark-text">SP-847739274</p>
        </span>
        <span>
          <p>Transaction Type</p>
          <p className="modal-list-dark-text">Electicity</p>
        </span>
        <span>
          <p>Amount</p>
          <p className="modal-list-dark-text">₦ 2,000</p>
        </span>
        <span>
          <p>Service Charge</p>
          <p className="modal-list-dark-text">₦ 100</p>
        </span>
        <span>
          <p>Remark</p>
          <p className="modal-list-dark-text">N/A</p>
        </span>
        <span>
          <p>Status</p>
          <p className="modal-list-success-text">Success</p>
        </span>
        <span>
          <p>Payment Method</p>
          <p className="modal-list-dark-text">Debit Card</p>
        </span>
        </div>
        <div className="transaction-modal-btn__container">
          <SapaButton
            label={"Download"}
            shape="rounded"
            buttonType="primary"
          />
          <SapaButton
            label={"Refund"}
            shape="rounded"
            buttonType="transparent"
          />
        </div>
      </div>
    </>
  );
};

export default TransactionDetails;
