import React from "react";
import { Avatar, Layout, message, Space, Typography } from "antd";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo/Logo1.png";
import { Form, Input } from "antd";
import SapaButton from "../../ui/Button/Button";
import Instagram from "../../assets/media-icons/Instagram.png";
import LinkedIn from "../../assets/media-icons/Linkedin.png";
import Twitter from "../../assets/media-icons/Twitter.png";
import { useDispatch, useSelector } from "react-redux";
import { login } from './../../redux/actions/auth';
import { selectAuthLoading } from "../../redux/selectors/authSelectors";
import { redirectAfterDelay } from "../../utils/utils";



function Signin() {
  const { Footer, Content } = Layout;
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAuthLoading);

  const onLoginRequest = (values) => {
    dispatch(login(values)).then((response) => {
      message.success(response?.message);
      
      redirectAfterDelay('/dashboard');
    }).catch(error => console.log(error));
  }

  return (
    <Layout className="sapa-signin__container">
      <Space direction="vertical" size={245}>
        <Content className="sapa-signin__inner">
          <div>
            <Link to={"/"}>
              <span className=" sapa-signin__circle">
                <span>
                  {" "}
                  <ion-icon name="arrow-back"></ion-icon>{" "}
                </span>
                <p>Go Back</p>
              </span>
            </Link>
            <Avatar className=" sapa-signin__avatar " src={Logo} size={40} />
          </div>
          <p>Authorize access</p>
          <Form name="basic" layout="vertical" autoComplete="off" onFinish={onLoginRequest}>
            <Form.Item
              label={<label>Email address</label>}
              name="username"
              rules={[
                { required: true, message: "Please enter your email" },
                {
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
              hasFeedback
            >
              <Input
                className="sapa-signin__input"
                placeholder="Email address"
              />
            </Form.Item>

            <Form.Item
              label={<label>Password</label>}
              name="password"
              className="sapa-signin__inputWrapper"
              rules={[{ required: true }]}
              hasFeedback
            >
              <Input.Password
                className="sapa-signin__password"
                placeholder="********"
              />
            </Form.Item>
            <Form.Item>
              <SapaButton
                label={"Access account"}
                customClass="sapa-signin__button"
                shape="round"
                buttonType="primary"
                htmlType='submit'
                loading={isLoading}
              //onClick={() => navigate("/dashboard")}
              />
            </Form.Item>
          </Form>
        </Content>
        <Footer className="sapa-signin__footer">
          <div>
            <span className="sapa-signin__footerLinks">
              <Typography.Link>About Us</Typography.Link>
              <Typography.Link>Help Center</Typography.Link>
              <Typography.Link>Privacy</Typography.Link>
              <Typography.Link>Terms of Use</Typography.Link>
            </span>
            <span className="sapa-signin__footerIcons">
              <img src={Instagram} alt="/" />
              <img src={LinkedIn} alt="/" />
              <img src={Twitter} alt="/" />
            </span>
          </div>
        </Footer>
      </Space>
    </Layout>
  );
}

export default Signin;
