import { Card, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import SapaHeader from "../../ui/Header/Header";
import Filterbox from "../../ui/FilterBox/Filterbox";
import { usersColumn } from "../../DummyAPI/TableData/Columns";
//import Overview from "./UserPages/Overview";
import { SapaContext } from "../../context/Context";
import { useDispatch, useSelector } from "react-redux";
import { selectUsers, selectUsersLoading } from './../../redux/selectors/userSelectors';
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { filterUsers, listUsers, searchUsers } from './../../redux/actions/user';


const Users = () => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(10);
  //const { showOverview } = useContext(SapaContext);

  const users = useSelector(selectUsers);
  const isLoading = useSelector(selectUsersLoading);

  const tableStyle = {
    tableLayout: "fixed",
  };

  // function to load more or less table data
  const showMoreOrLessHandler = () => {
    if (visible === 10) {
      setVisible(users.length);
    } else {
      setVisible(10);
    }
  };

  // adding classname to odd table rows
  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  useEffect(() => {
    dispatch(listUsers());
  }, [dispatch]);

  const columnKeys = usersColumn.map((column) => column.key);

  return (
    <ProgressLoader isLoading={isLoading}>
      <div className="Users">
        {/*  {!showOverview && (
          <>
            {" "}
            <SapaHeader page={"Users"} />
            <Card className="sapa-users__container">
              <Filterbox type={"usersType"} filterType={"date added"} />
              <Table
                key={columnKeys}
                dataSource={users}
                columns={usersColumn}
                pagination={false}
                rowClassName={rowClassName}
                style={tableStyle}
              />
              <div className="sapa-users-table__showbtn_container">
                <button onClick={showMoreOrLessHandler}>
                  {visible === 20 ? "Load Less" : "Load More"}
                </button>
              </div>
            </Card>
          </>
        )}
        {showOverview && (
          <>
            <Overview />
          </>
        )} */}

        <SapaHeader page={"Users"} />
        <Card className="sapa-users__container">
          <Filterbox 
            type={"usersType"} 
            filterType={"Status, Tier or Date"} 
            searchIsLoading={isLoading}
            searchAction={listUsers}
            applyFilterAction={listUsers}
          />
          <Table
            key={columnKeys}
            dataSource={users}
            columns={usersColumn}
            pagination={false}
            rowClassName={rowClassName}
            style={tableStyle}
          />
          <div className="sapa-users-table__showbtn_container">
            <button onClick={showMoreOrLessHandler}>
              {visible === 20 ? "Load Less" : "Load More"}
            </button>
          </div>
        </Card>

      </div>
    </ProgressLoader>
  );
};

export default Users;
