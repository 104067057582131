import { HIDE_MODAL, SHOW_MODAL } from "../types";
import _noop from "lodash/noop";

const initialState = {
  visible: false,
  title: null,
  content: null,
  handleOk: _noop,
};

function modalReducer(state = initialState, action) {
  const { type, payload = {} } = action;

  switch (type) {
    case SHOW_MODAL:
      const { title, content, handleOk } = payload;
      return { ...state, visible: true, title, content, handleOk };
    case HIDE_MODAL:
      return { ...state, visible: false };
    default:
      return state;
  }
}

export default modalReducer;
