

const Chip = ({ label, onClick }) => {
    return (
    <span
        style={
            {
                backgroundColor: 'rgba(151, 71, 255, 0.1)',
                color: "rgba(151, 71, 255, 1)",
                fontWeight: 500,
                fontSize: "11px",
                padding: "6px 16px",
                borderRadius: "26px",
                cursor: "pointer"
            }
        }
        onClick={onClick}
    >
        {label}
    </span>);
}

export default Chip;