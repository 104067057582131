import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJs } from "chart.js/auto";

const Dashboard_LineChart = ({ chartData }) => {
  const options = {
    maintainAspectRatio: false,
    tension: 0.4,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: { color: "black" },
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: function (label, index, labels) {
            switch (index) {
              case 0:
                return "$0";
              case 1:
                return "$5k";
              case 2:
                return "$10k";
              case 3:
                return "$20k";
              case 4:
                return "$30k";
              case 5:
                return "$50k";
            }
          },
          color: "black",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div>
      <Line options={options} data={chartData} height="330px" />
    </div>
  );
};

export default Dashboard_LineChart;
