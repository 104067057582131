// api/verification.js
import apiClient from './apiClient';

// Send OTP API request
export const sendOtpRequest = (data) =>
  apiClient({
    method: 'POST',
    url: '/verification/otp/send',
    data,
  });

// Verify OTP API request
export const verifyOtpRequest = (data) =>
  apiClient({
    method: 'POST',
    url: '/verification/otp/verify',
    data,
  });
