// sagas/userSaga.js

import { call, all, takeEvery } from 'redux-saga/effects';
import {
  listUsersRequest,
  exportUsersRequest,
  blockUserRequest,
  unblockUserRequest,
  deleteUserRequest,
  updateUserRequest,
  getUserInfoRequest,
  updateWalletBalanceRequest,
  updateUserCommissionRequest,
  listUserCommissionsRequest,
  getUserTransactionTraceRequest
} from './../../api/user'; // API imports

import {
  listUsers,
  filterUsers,
  searchUsers,
  exportUsers,
  blockUser,
  unblockUser,
  deleteUser,
  updateUser,
  getUserInfo,
  updateWalletBalance,
  updateUserCommission,
  listUserCommissions,
  getUserTransactionTrace
} from '../actions/user'; // Promise actions

import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import { translateErrorResponse } from '../../utils/utils';

// Saga to handle listing users
export function* handleListUsers(action) {
  try {
    const response = yield call(listUsersRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle exporting users
export function* handleExportUsers(action) {
  try {
    const response = yield call(exportUsersRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle blocking users
export function* handleBlockUser(action) {
  try {
    const response = yield call(blockUserRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle unblocking users
export function* handleUnblockUser(action) {
  try {
    const response = yield call(unblockUserRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle deleting users
export function* handleDeleteUser(action) {
  try {
    const response = yield call(deleteUserRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle updating user details
export function* handleUpdateUser(action) {
  try {
    const response = yield call(updateUserRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle getting user info
export function* handleGetUserInfo(action) {
  try {
    const response = yield call(getUserInfoRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle updating wallet balance
export function* handleUpdateWalletBalance(action) {
  try {
    const response = yield call(updateWalletBalanceRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle updating user commission
export function* handleUpdateUserCommission(action) {
  try {
    const response = yield call(updateUserCommissionRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle listing user commissions
export function* handleListUserCommissions(action) {
  try {
    const response = yield call(listUserCommissionsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle getting user transaction trace
export function* handleGetUserTransactionTrace(action) {
  try {
    const response = yield call(getUserTransactionTraceRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for user actions
export default function* userSaga() {
  yield all([
    takeEvery(listUsers.trigger, handleListUsers), // Watch for listUsers action
    takeEvery(filterUsers.trigger, handleListUsers), // Watch for filterUsers action
    takeEvery(searchUsers.trigger, handleListUsers), // Watch for searchUsers action
    takeEvery(exportUsers.trigger, handleExportUsers), // Watch for exportUsers action
    takeEvery(blockUser.trigger, handleBlockUser), // Watch for blockUser action
    takeEvery(unblockUser.trigger, handleUnblockUser), // Watch for unblockUser action
    takeEvery(deleteUser.trigger, handleDeleteUser), // Watch for deleteUser action
    takeEvery(updateUser.trigger, handleUpdateUser), // Watch for updateUser action
    takeEvery(getUserInfo.trigger, handleGetUserInfo), // Watch for getUserInfo action
    takeEvery(updateWalletBalance.trigger, handleUpdateWalletBalance), // Watch for updateWalletBalance action
    takeEvery(updateUserCommission.trigger, handleUpdateUserCommission), // Watch for updateUserCommission action
    takeEvery(listUserCommissions.trigger, handleListUserCommissions), // Watch for listUserCommissions action
    takeEvery(getUserTransactionTrace.trigger, handleGetUserTransactionTrace) // Watch for getUserTransactionTrace action
  ]);
}
