import { createPromiseAction } from '@adobe/redux-saga-promise';

// Actions for filtering transactions
export const filterTransactions = createPromiseAction('transaction/filter');

// Actions for searching transactions
export const searchTransactions = createPromiseAction('transaction/search');

// Actions for exporting transactions
export const exportTransactions = createPromiseAction('transaction/export');

// Actions for listing transactions
export const listTransactions = createPromiseAction('transaction/list');

// Actions for getting transaction details
export const getTransactionDetails = createPromiseAction('transaction/getDetails');
