import { Dropdown, Menu } from "antd";
import React, { useState } from "react";
import arrow from "../../../assets/svgs/header-icon/arrow-down.svg";
import arrowGrey from "../../../assets/svgs/header-icon/arrow-down-grey.svg";

import TransactionStatusItems from "../FilterItems/TransactionStatusItems";
import SpMenu from "../../SpMenu/SpMenu";

const TransactionStatus = ({onChange}) => {
  const [status, setStatus] = useState("Search bills");
  const [selected, setSelected] = useState(false);

  function handleFilterSelect({item, index}) {
    setStatus(item?.status?? 'All');
    setSelected(true);

    if (typeof onChange === "function") {
      onChange(item?.status?? '');
    }
  }

  const menu = (
    <SpMenu items={TransactionStatusItems} renderItem={(item) => <p>{item.status}</p>} onSelect={handleFilterSelect} />
  );


  return (
    <div className="filter-modal-inner">
      <p>Transaction Status</p>
      <Dropdown overlay={menu} trigger={["click"]}>
        <button
          className={
            selected ? "selected filter-modal-btn" : "filter-modal-btn"
          }
        >
          {" "}
          {status}
          <span className="filter-icon">
            {selected ? (
              <img src={arrow} alt="/" />
            ) : (
              <img src={arrowGrey} alt="/" />
            )}
          </span>{" "}
        </button>
      </Dropdown>
    </div>
  );
};

export default TransactionStatus;
