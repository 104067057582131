import createSagaMiddleware from "redux-saga";
import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "./reducers";
import rootSaga from "./sagas";
import logger from "redux-logger";
import { promiseMiddleware } from "@adobe/redux-saga-promise";

export const sagaMiddleware = createSagaMiddleware();
export const store = configureStore({
  reducer: rootReducers,
  middleware: () => [promiseMiddleware, sagaMiddleware, logger],
});

sagaMiddleware.run(rootSaga);
