import { Card, Table } from "antd";
import React, { useContext, useEffect } from "react";
import Filterbox from "../../ui/FilterBox/Filterbox";
import SapaHeader from "../../ui/Header/Header";
import { apiProvidersColumn } from "../../DummyAPI/TableData/Columns";
import Overview from "./ProviderPages/Overview";
import { SapaContext } from "../../context/Context";
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectProvidersData, selectProvidersLoading } from './../../redux/selectors/providerSelectors';
import { listProviders } from './../../redux/actions/provider';

const ApiProviders = () => {
  const dispatch = useDispatch();
  const { showOverview } = useContext(SapaContext);
  const isLoading = useSelector(selectProvidersLoading);
  const providerList = useSelector(selectProvidersData);

  const tableStyle = {
    tableLayout: "fixed",
  };

  // adding classname to odd table rows
  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  useEffect(() => {
    dispatch(listProviders());
  }, [dispatch]);


  return (
    <ProgressLoader isLoading={isLoading}>
      <div className="ApiProviders">
        {/*  {!showOverview && (
          <>
            {" "}
            <SapaHeader page={"API Providers"} />
            <Card className="sapa-apiproviders__container">
              <Filterbox displayFilter={"none"} />
              <Table
                key={Math.random().toString()}
                dataSource={providerList}
                columns={apiProvidersColumn}
                pagination={false}
                rowClassName={rowClassName}
                style={tableStyle}
              />
            </Card>{" "}
          </>
        )}
        {showOverview && (
          <>
            <Overview />
          </>
        )} */}

        <SapaHeader page={"API Providers"} />
        <Card className="sapa-apiproviders__container">
          <Filterbox 
            displayFilter={"none"}
            searchIsLoading={isLoading}
            searchAction={listProviders}
          />
          <Table
            key={Math.random().toString()}
            dataSource={providerList}
            columns={apiProvidersColumn}
            pagination={false}
            rowClassName={rowClassName}
            style={tableStyle}
          />
        </Card>
      </div>
    </ProgressLoader>
  );
};

export default ApiProviders;
