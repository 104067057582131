import MainPage from "../pages/MainPage/Main";
import { Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "../pages/Dashboard/Main";
import Transactions from "../pages/Transactions/Main";
import Signin from "../pages/Signin/Signin";
import Services from "../pages/Services/Main";
import ApiProviders from "../pages/APIProviders/Main";
import Users from "../pages/Users/Main";
import Alerts from "../pages/Alerts/Main";
import Administrators from "../pages/Administrators/Main";
import Security from "../pages/Security/Main";

const Root = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={"/login"} />} />
      <Route path="/login" element={<Signin />} />
      <Route path="/dashboard" element={<MainPage />}>
        <Route path="" element={<Navigate to="/dashboard/overview" />} />
        <Route path="overview" element={<Dashboard />} />
        <Route path="transactions" element={<Transactions />} />
        <Route 
          path="services/:slug?" 
          element={<Services />}
          children={[
            <Route index path="" />,
            <Route path="*" element={<Services />} />
          ]}
        />
        <Route path="api-providers" element={<ApiProviders />} />
        <Route path="users" element={<Users />} />
        <Route path="alerts" element={<Alerts />} />
        <Route path="administrators" element={<Administrators />} />
        <Route path="security" element={<Security />} />
      </Route>
    </Routes>
  );
};

export default Root;
