export const ChartData = [
    {
        id: 1,
        Month: "Jan",
        Profit: 0,
        Revenue: 0,
     },
    {
        id: 2,
        Month: "Feb",
        Profit: 2,
        Revenue: 2    },
    {
        id: 3,
        Month: "Mar",
        Profit: 5,
        Revenue: 3
    },
    {
        id: 4,
        Month: "Apr",
        Profit: 9,
        Revenue: 9
    },
    {
        id: 5,
        Month: "May",
        Profit: 3,
        Revenue: 7
    },
    {
        id: 6,
        Month: "Jun",
        Profit: 5,
        Revenue: 5
    },
    {
        id: 7,
        Month: "Jul",
        Profit: 8,
        Revenue: 5
    },
    {
        id: 8,
        Month: "Aug",
        Profit: 5,
        Revenue: 12
    },
    {
        id: 9,
        Month: "Sep",
        Profit: 4,
        Revenue: 9
    },
    {
        id: 10,
        Month: "Oct",
        Profit: 3,
        Revenue: 6
    },
    {
        id: 11,
        Month: "Nov",
        Profit: 2,
        Revenue: 5
    },
    {
        id: 12,
        Month: "Dec",
        Profit: 4,
        Revenue: 9
    },
];