import React, { Fragment, useMemo } from "react";
import { Layout, Menu } from "antd";
import { dashboardMenus } from "./MainPage.helper";
import Logo from "../../assets/logo/Logo.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
const { Content, Sider } = Layout;

const MainPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathName = location.pathname;
  // const { Dashboard } = MENU_KEYS;

  const selected = pathName.substring(11)
    ? pathName.substring(11)
    : "dashboard";
  
  const getMenuItems = useMemo(() => {
    if (selected) {
      const menus = dashboardMenus(selected.toLowerCase());
      console.log(menus);
      return menus;
    }
    return [];
  }, [selected]);

  return (
    <Content className="MainPage">
      <Layout className="MainPage__layout">
        <Sider className="MainPage__side" breakpoint="sm">
          <div className="sapa-menu__logo">
            <img src={Logo} width={64} alt="/" />
          </div>
          <Menu
            mode="inline"
            className="MainPage__menu"
            selectedKeys={[selected]}
          >
            {getMenuItems.map(({ key, icon, label }) => (
              <Fragment>
                {selected?.toLowerCase() === key?.toLowerCase() ? (
                  <span className="hover-bar"></span>
                ) : (
                  <span className="invisible-bar"></span>
                )}
                <Menu.Item
                  className="MainPage__menu_item"
                  icon={icon}
                  key={key}
                  label={label}
                  onClick={(e) => {
                    // setActiveKey(e.key);
                    navigate(e.key?.toLowerCase());
                  }}
                >
                  <Fragment>
                    {label}
                    {/* {activeKey === key && (
                    <ion-icon name="arrow-forward-outline"></ion-icon>
                  )} */}
                  </Fragment>
                </Menu.Item>
              </Fragment>
            ))}
          </Menu>
        </Sider>
        <Content className="MainPage__content">
          <Outlet />
        </Content>
      </Layout>
    </Content>
  );
};

export default MainPage;
