import { createReducer } from '@reduxjs/toolkit';
import { sendOtp, verifyOtp } from '../actions/verification';

const initialState = {
  sendOtp: {
    loading: false,
    error: null,
    data: null, // Data related to OTP sending
  },
  verifyOtp: {
    loading: false,
    error: null,
    data: null, // Data related to OTP verification
  },
};

const verificationsReducer = createReducer(initialState, {
  // Send OTP actions
  [sendOtp.trigger]: (state) => {
    state.sendOtp.loading = true;
    state.sendOtp.error = null;
    state.sendOtp.data = null;
  },
  [sendOtp.resolved]: (state, action) => {
    state.sendOtp.loading = false;
    state.sendOtp.data = action.payload; // Set data to the payload from the successful OTP send response
  },
  [sendOtp.rejected]: (state, action) => {
    state.sendOtp.loading = false;
    state.sendOtp.error = action.payload; // Set error to the payload from the failed OTP send response
  },

  // Verify OTP actions
  [verifyOtp.trigger]: (state) => {
    state.verifyOtp.loading = true;
    state.verifyOtp.error = null;
    state.verifyOtp.data = null;
  },
  [verifyOtp.resolved]: (state, action) => {
    state.verifyOtp.loading = false;
    state.verifyOtp.data = action.payload; // Set data to the payload from the successful OTP verification response
  },
  [verifyOtp.rejected]: (state, action) => {
    state.verifyOtp.loading = false;
    state.verifyOtp.error = action.payload; // Set error if verification fails
  },
});

export default verificationsReducer;
