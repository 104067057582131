import Button from "../../pages/APIProviders/ProviderPages/button";
import SapaButton from "../../ui/Button/Button";
import SaveBtn from "../../ui/Button/SaveBtn";
import EditButton from "../../ui/EditButton/EditButton";
import SapaInput from "../../ui/Input/Input";
import TableActionBtn from "../../ui/TableIcon/TableActionBtn";
export const transactionPageRows = [
  {
    date: "21 Mar 19:30 WAT",
    transaction: "Electicity",
    payment: "Credit",
    reference: "SP-847739274",
    amount: "₦ 1,000",
    status: "Success",
    action: <TableActionBtn />,
  },
];

export const servicePageRows = [
  {
    serviceName: "AEDC",
    serviceType: "Electricity",
    providers: "3",
    currentProvider: "Airvend",
    action: <EditButton />,
  },
];

export const apiProvidersPageRows = [
  {
    providerName: "Airvend",
    services: 10,
    walletBalance: "₦ 100,000",
    totalTransactions: "653,686",
    action: <Button />,
  },
  {
    providerName: "iRecharge",
    services: 10,
    walletBalance: "₦ 100,000",
    totalTransactions: "653,686",
    action: <Button />,
  },
  {
    providerName: "BuyPower",
    services: 10,
    walletBalance: "₦ 100,000",
    totalTransactions: "653,686",
    action: <Button />,
  },
  {
    providerName: "Shago",
    services: 10,
    walletBalance: "₦ 100,000",
    totalTransactions: "653,686",
    action: <Button />,
  },
];

export const overviewRows = [
  {
    serviceName: "AEDC",
    currentCommission: "2.5%",
    updateCommission: (
      <SapaInput
        placeholder={"Type here"}
        inputClass={"overview-table-input"}
      />
    ),
    action: <SaveBtn />,
  },
  {
    serviceName: "DSTV",
    currentCommission: "2.5%",
    updateCommission: (
      <SapaInput
        placeholder={"Type here"}
        inputClass={"overview-table-input"}
      />
    ),
    action: <SaveBtn />,
  },
  {
    serviceName: "IPNX",
    currentCommission: "2.5%",
    updateCommission: (
      <SapaInput
        placeholder={"Type here"}
        inputClass={"overview-table-input"}
      />
    ),
    action: <SaveBtn />,
  },
  {
    serviceName: "MTN",
    currentCommission: "2.5%",
    updateCommission: (
      <SapaInput
        placeholder={"Type here"}
        inputClass={"overview-table-input"}
      />
    ),
    action: <SaveBtn />,
  },
  {
    serviceName: "WAEC",
    currentCommission: "2.5%",
    updateCommission: (
      <SapaInput
        placeholder={"Type here"}
        inputClass={"overview-table-input"}
      />
    ),
    action: <SaveBtn />,
  },
  {
    serviceName: "NIBSS",
    currentCommission: "2.5%",
    updateCommission: (
      <SapaInput
        placeholder={"Type here"}
        inputClass={"overview-table-input"}
      />
    ),
    action: <SaveBtn />,
  },
  {
    serviceName: "BET9JA",
    currentCommission: "2.5%",
    updateCommission: (
      <SapaInput
        placeholder={"Type here"}
        inputClass={"overview-table-input"}
      />
    ),
    action: <SaveBtn />,
  },
];

export const usersPageRows = [
  {
    date: "21 Mar 19:30 WAT",
    name: "Annette Black",
    number: "078 2181 5063",
    email: "name@email.com",
    balance: "₦ 1,000",
    tier: "Tier 1",
    action: <TableActionBtn table={"usersTable"} />,
  },
];

export const alertsPageRows = [
  {
    date: "21 Mar 19:30 WAT",
    note: "Even the experts do not understand it the way they would like to, and it is perfectly reasonable that they should not, because all of direct, human experience and of human intuition applies to large objects.",
    action: <EditButton modal={"alertsModal"} />,
  },
  {
    date: "21 Mar 19:30 WAT",
    note: "They do not behave like waves, they do not behave like particles, they do not behave like clouds, or billiard balls, or weights on springs, or like anything that you have ever seen.",
    action: <EditButton modal={"alertsModal"} />,
  },
  {
    date: "21 Mar 19:30 WAT",
    note: "This proved to be impossible using the traditional concepts of space and time. Einstein developed a new view of time first and then space.",
    action: <EditButton modal={"alertsModal"} />,
  },
  {
    date: "21 Mar 19:30 WAT",
    note: "So what we learn about the properties of electrons (which we shall use for our examples) will apply also to all “particles,” including photons of light.",
    action: <EditButton modal={"alertsModal"} />,
  },
];

export const administratorsPageRows = [
  {
    date: "21 Mar 19:30 WAT",
    email: "trungkienspktnd@gamail.com",
    name: "Marvin McKinney",
    action: <TableActionBtn table={"administratorsTable"} />,
  },
  {
    date: "21 Mar 19:30 WAT",
    email: "trungkienspktnd@gamail.com",
    name: "Marvin McKinney",
    action: <TableActionBtn table={"administratorsTable"} />,
  },
  {
    date: "21 Mar 19:30 WAT",
    email: "trungkienspktnd@gamail.com",
    name: "Marvin McKinney",
    action: <TableActionBtn table={"administratorsTable"} />,
  },
  {
    date: "21 Mar 19:30 WAT",
    email: "trungkienspktnd@gamail.com",
    name: "Marvin McKinney",
    action: <TableActionBtn table={"administratorsTable"} />,
  },
];
