import { Badge } from "antd";
import TableActionBtn from "../../ui/TableIcon/TableActionBtn";
import Chip from "../../components/Chip/Chip";
import TableActionChip from "../../components/TableActionChip/TableActionChip";
import { formatAmount } from './../../helpers/numbers';

const formatDate = (dt) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return new Date(dt).toLocaleDateString("en-US", options);
};

export const columns = [
  {
    width: "16.66%",
    title: "DATE & TIME",
    dataIndex: "created_at",
    sorter: true,
    key: "created_at",
    render: (text) => formatDate(text),
  },
  {
    width: "16.66%",
    title: "TRANSACTION TYPE",
    dataIndex: "req",
    sorter: true,
    key: "transaction_type",
    render: (value) => value?.service_type?.toUpperCase(),
  },
  {
    width: "16.66%",
    title: "PAYMENT METHOD",
    dataIndex: "payment_method",
    key: "payment_method",
  },
  {
    width: "16.66%",
    title: "REFERENCE",
    dataIndex: "transaction_reference",
    key: "reference",
  },
  {
    width: "16.66%",
    title: "AMOUNT",
    dataIndex: "amount",
    key: "amount",
  },
  {
    width: "16.66%",
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
  {
    width: "16.66%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
    render: (text, record) => <TableActionBtn />,
  },
];
export const servicesColumn = [
  {
    width: "40%",
    title: "SERVICE NAME",
    dataIndex: "name",
    sorter: true,
    key: "serviceName",
  },
  
  {
    width: "20%",
    title: "PROVIDERS",
    dataIndex: "total_providers_assigned",
    key: "providers"
  },
  {
    width: "25%",
    title: "CURRENT PROVIDER",
    dataIndex: "active_provider",
    key: "currentProvider",
    render: (value) => value?.name?? '',
  },
  {
    width: "15%",
    title: "ACTION",
    dataIndex: "id",
    key: "action",
    render: (value) => {
      return <Chip label="Switch" />
    }
  },
];

export const serviceCategoriesColumn = [
  {
    width: "22.5%",
    title: "CATEGORY",
    dataIndex: "name",
    sorter: true,
    key: "serviceName",
  },
  {
    width: "22.5%",
    title: "SERVICES",
    dataIndex: "service_counts",
    sorter: true,
    key: "serviceCounts"
  },
  {
    width: "20%",
    title: "DESCRIPTION",
    dataIndex: "description",
    key: "description"
  },
  {
    width: "20%",
    title: "STATUS",
    dataIndex: "status",
    key: "status",
  },
  {
    width: "15%",
    title: "ACTION",
    dataIndex: "slug",
    key: "action",
    render: (value) => {
      return <TableActionChip url = {`/dashboard/services/${value}`} />
    }
  },
];

export const apiProvidersColumn = [
  {
    width: "30%",
    title: "PROVIDER NAME",
    dataIndex: "name",
    sorter: true,
    key: "providerName",
  },
  {
    width: "25%",
    title: "WALLET BALANCE",
    dataIndex: "balance",
    key: "balance",
  },
  {
    width: "25%",
    title: "TOTAL TRANSACTIONS",
    dataIndex: "transaction_counts",
    key: "transactionCounts",
  },
  {
    width: "15%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
    render: (value) => {
      return <Chip label="Update" />
    }
  },
];

export const overviewColumn = [
  {
    width: "28%",
    title: "SERVICE NAME",
    dataIndex: "serviceName",
    sorter: true,
    key: "serviceName",
  },
  {
    width: "28%",
    title: "CURRENT COMMISSION",
    dataIndex: "currentCommission",
    sorter: true,
    key: "currentCommission",
  },
  {
    width: "28%",
    title: "UPDATE COMMISSION",
    dataIndex: "updateCommission",
    key: "updateCommission",
  },
  {
    width: "16%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
  },
];

export const usersColumn = [
  {
    width: "20%",
    title: "DATE JOINED",
    dataIndex: "created_at",
    sorter: true,
    key: "date",
    render: (value) => formatDate(value),
  },
  {
    width: "20%",
    title: "FULL NAME",
    dataIndex: "user_profile",
    sorter: true,
    key: "full_name",
    render: (value) => value?.full_name,
  },
  {
    width: "16.66%",
    title: "PHONE NUMBER",
    dataIndex: "phone_number",
    sorter: true,
    key: "phone_number",
  },
  {
    width: "16.66%",
    title: "EMAIL ADDRESS",
    dataIndex: "email",
    sorter: true,
    key: "email_address",
  },
  {
    width: "16.66%",
    title: "WALLET BALANCE",
    dataIndex: "wallet",
    key: "balance",
    render: (value) => {
      return formatAmount(value?.main_balance?? 0);
    }
  },
  {
    width: "16.66%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
    render: (text, record) => <TableActionBtn table={"usersTable"} />,
  },
];

export const alertsColumn = [
  {
    width: "17%",
    title: "DATE JOINED",
    dataIndex: "date",
    sorter: true,
    key: "date",
  },
  {
    width: "73.5%",
    title: "NOTE",
    dataIndex: "note",
    key: "note",
  },
  {
    width: "10%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
  },
];

export const administratorsColumn = [
  {
    width: "30%",
    title: "DATE ADDED",
    dataIndex: "date",
    sorter: true,
    key: "date",
  },
  {
    width: "30%",
    title: "EMAIL ADDRESS",
    dataIndex: "email",
    key: "email",
  },
  {
    width: "27.5%",
    title: "FULL NAME",
    dataIndex: "name",
    key: "name",
  },
  {
    width: "12.5%",
    title: "ACTION",
    dataIndex: "action",
    key: "action",
  },
];
