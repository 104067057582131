const TransactionStatusItems =[{
    id: 1,
    status: "All"
},
{
    id: 2,
    status: "Succesful"
},
{
    id: 3,
    status: "Pending"
},
{
    id: 4,
    status: "Failed"
},
{
    id: 5,
    status: "Refunded"
},]

export default TransactionStatusItems