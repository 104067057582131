// User selectors
export const selectUsers = (state) => state.user?.users?.data?? [];
export const selectCurrentPage = (state) => state.user?.users?.currentPage;
export const selectTotalPages = (state) => state.user?.users?.totalPages;
export const selectPageSize = (state) => state?.users.pageSize;

export const selectUsersLoading = (state) => state.user?.loading;
export const selectUsersError = (state) => state.user?.users?.error;

// Export selectors
export const selectExportLoading = (state) => state.user?.users?.export.loading;
export const selectExportSuccess = (state) => state.user?.users?.export.success;
export const selectExportError = (state) => state.user?.users?.export.error;

// Block user selectors
export const selectBlockUserSuccess = (state) => state.user?.users?.block.success;
export const selectBlockUserError = (state) => state.user?.users?.block.error;

// Unblock user selectors
export const selectUnblockUserSuccess = (state) => state.user?.users?.unblock.success;
export const selectUnblockUserError = (state) => state.user?.users?.unblock.error;

// Delete user selectors
export const selectDeleteUserSuccess = (state) => state.user?.users?.delete.success;
export const selectDeleteUserError = (state) => state.user?.users?.delete.error;

// Update user selectors
export const selectUpdateUserSuccess = (state) => state.user?.users?.update.success;
export const selectUpdateUserError = (state) => state.user?.users?.update.error;

// Get user info selectors
export const selectUserInfo = (state) => state.user?.users?.getUserInfo.data;
export const selectUserInfoLoading = (state) => state.user?.users?.getUserInfo.loading;
export const selectUserInfoError = (state) => state.user?.users?.getUserInfo.error;

// Update wallet balance selectors
export const selectUpdateWalletBalanceSuccess = (state) => state.user?.users?.updateWalletBalance.success;
export const selectUpdateWalletBalanceError = (state) => state.user?.users?.updateWalletBalance.error;

// Update user commission selectors
export const selectUpdateUserCommissionSuccess = (state) => state.user?.users?.updateUserCommission.success;
export const selectUpdateUserCommissionError = (state) => state.user?.users?.updateUserCommission.error;

// List user commissions selectors
export const selectUserCommissions = (state) => state.user?.users?.listUserCommissions.data;
export const selectUserCommissionsLoading = (state) => state.user?.users?.listUserCommissions.loading;
export const selectUserCommissionsError = (state) => state.user?.users?.listUserCommissions.error;

// Get user transaction trace selectors
export const selectUserTransactionTrace = (state) => state.user?.users?.getUserTransactionTrace.data;
export const selectUserTransactionTraceLoading = (state) => state.user?.users?.getUserTransactionTrace.loading;
export const selectUserTransactionTraceError = (state) => state.user?.users?.getUserTransactionTrace.error;
