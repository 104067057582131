import { call, all, takeEvery } from 'redux-saga/effects';
import { fetchDashboardStatisticsRequest, fetchRevenueDataRequest } from '../../api/dashboard';
import { fetchDashboardStatistics, fetchRevenueData } from '../actions/dashboard';
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

// Saga to handle fetching dashboard statistics
export function* handleFetchDashboardStatistics(action) {
  try {
    const response = yield call(fetchDashboardStatisticsRequest);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = err.response?.data?.message || 'An error occurred';
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle fetching revenue data
export function* handleFetchRevenueData(action) {
  try {
    const response = yield call(fetchRevenueDataRequest);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = err.response?.data?.message || 'An error occurred';
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for dashboard actions
export default function* dashboardSaga() {
  yield all([
    takeEvery(fetchDashboardStatistics.trigger, handleFetchDashboardStatistics), // Watch for fetchDashboardStatistics action
    takeEvery(fetchRevenueData.trigger, handleFetchRevenueData), // Watch for fetchRevenueData action
  ]);
}
