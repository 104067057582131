import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Chip from "../Chip/Chip";


const TableActionChip = ({url}) => {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(url);
    }, [navigate, url]);
    
    return (<Chip label="View Details" onClick={handleClick} />);
    }

export default TableActionChip;