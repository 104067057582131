import { Card, Table } from "antd";
import React from "react";
import { administratorsColumn } from "../../DummyAPI/TableData/Columns";
import { administratorsPageRows } from "../../DummyAPI/TableData/Datasource";
import Filterbox from "../../ui/FilterBox/Filterbox";
import SapaHeader from "../../ui/Header/Header";

const Administrators = () => {
  const tableStyle = {
    tableLayout: "fixed",
  };

  // adding classname to odd table rows
  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };
  return (
    <div className="Administrators">
      <SapaHeader page={"Administrators"} />
      <Card className="sapa-administrators__container">
        <Filterbox displayFilter={"none"} showAdmin={true} />
        <Table
          key={Math.random().toString()}
          dataSource={administratorsPageRows}
          columns={administratorsColumn}
          pagination={false}
          rowClassName={rowClassName}
          style={tableStyle}
        />
      </Card>
    </div>
  );
};

export default Administrators;
