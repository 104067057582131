import { Avatar } from "antd";
import React from "react";
import FailedIcon from "../../../../assets/svgs/svg-icons/FailedIcon";
import SuccessIcon from "../../../../assets/svgs/svg-icons/SuccessIcon";

const TraceTabsHelper = () => {
  return (
    <div className="transaction-trace__lower-section_list">
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<SuccessIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Pay with Debit Card</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<SuccessIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Pay with Sapa Wallet</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<SuccessIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Pay with Credit</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<SuccessIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Flutterwave Queried</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<SuccessIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Card Debit Succesful</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<SuccessIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Request Sent to Airvend AEDC</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<SuccessIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Respond:200 OK</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<FailedIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Response: insufficient balance</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<FailedIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Response: 500 internal error</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<FailedIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">No response</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<FailedIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Network error</p>
        </span>
      </div>
      <div className="transaction-trace__lower-section-inner">
        <Avatar src={<FailedIcon />} />
        <span>
          <p>01:23 am</p>
          <p className="modal-list-dark-text">Transaction failed</p>
        </span>
      </div>
    </div>
  );
};

export default TraceTabsHelper;
