import React, { useState } from "react";
import SapaButton from "../../ui/Button/Button";
import ApiProviders from "./FilterComponents/ApiProviders";
import DateAdded from "./FilterComponents/DateAdded";
import KycTier from "./FilterComponents/KYC_Tier";
import PaymentMethods from "./FilterComponents/PaymentMethod";
import TransactionFilter from "./FilterComponents/TransactionFilter";
import TransactionStatus from "./FilterComponents/TransactionStatus";
import UserStatus from "./FilterComponents/UserStatus";


const FilterModal = ({ type, onClick, visible, setVisible, onApplyFilter }) => {

  const [formData, setFormData] = useState({});

  const handleChange = (field, value) => {
    setFormData({...formData, [field]: value});
  }

  let box;

  switch (type) {
    case "apiType":
      box = <>
        <TransactionFilter onChange={(value) => {
          handleChange('type', value);
        }} />
        <TransactionStatus onChange={(value) => {
          handleChange('status', value);
        }} />
        <PaymentMethods onChange={(value) => {
          handleChange('payment_method', value);
        }} />
      </>
      break;
    case "usersType":
      box = <>
        <DateAdded onChange={(value) => {
          handleChange('date_added', value);
        }} />
        
        <UserStatus onChange={(value) => {
          handleChange('status', value);
        }} />
        <KycTier onChange={(value) => {
          handleChange('user_tier', value);
        }} />
      </>
      break;
    case "transactionType":
      box = <>
        <TransactionFilter onChange={(value) => {
          handleChange('type', value);
        }} />
        <TransactionStatus onChange={(value) => {
          handleChange('status', value);
        }} />
        <PaymentMethods onChange={(value) => {
          handleChange('payment_method', value);
        }} />
        <ApiProviders onChange={(value) => {
          handleChange('provider', value);
        }} />
      </>
      break;
    default:
      box = <>
        <TransactionFilter onChange={(value) => {
          handleChange('type', value);
        }} />
        <TransactionStatus onChange={(value) => {
          handleChange('status', value);
        }} />
        <PaymentMethods onChange={(value) => {
          handleChange('payment_method', value);
        }} />
        <ApiProviders onChange={(value) => {
          handleChange('provider', value);
        }} />
      </>
 
  }

  const handleApplyFilter = () => {
    if (typeof onApplyFilter === 'function') {
      onApplyFilter(formData);
    }
  }


  return (<div className="filter-modal">
    <div className="filter-modal-container">
      {box}
      <div className="filter-modal-btn__container">
        <SapaButton
          label={"Apply Filter"}
          customClass="filter-modal-btn__fill"
          shape="round"
          buttonType="primary"
          onClick={handleApplyFilter}
        />
        <SapaButton
          label={"Cancel"}
          customClass="filter-modal-btn__transparent"
          shape="round"
          buttonType="transparent"
          onClick={(onClick = () => setVisible(!visible))}
        />
      </div>
    </div>
  </div>);
};

export default FilterModal;
