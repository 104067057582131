// api/transaction.js

import apiClient from './apiClient';

// Request to filter transactions
export const filterTransactionsRequest = (filters) =>
  apiClient({
    method: 'POST',
    url: '/transactions/filter',
    data: filters,
  });

// Request to search transactions
export const searchTransactionsRequest = (query) =>
  apiClient({
    method: 'POST',
    url: '/transactions/search',
    params: { query },
  });

// Request to export transactions
export const exportTransactionsRequest = (format) =>
  apiClient({
    method: 'POST',
    url: '/transactions/export',
    params: { format },
  });

// Request to list transactions
export const listTransactionsRequest = () =>
  apiClient({
    method: 'POST',
    url: '/transactions',
  });

// Request to get transaction details
export const getTransactionDetailsRequest = (id) =>
  apiClient({
    method: 'POST',
    url: `/transactions/${id}`,
  });
