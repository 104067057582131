import axios from "axios";
import { stringify } from "qs";
import { message } from "antd";
import { getAccessToken, redirectAfterDelay, setAccessToken, sleep } from './../utils/utils';

const config = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {},
  paramsSerializer: {
    serialize: (params) => stringify(params, { arrayFormat: "brackets" }),
  },
};

const instance = axios.create(config);

instance.interceptors.request.use(
  async function (_config) {
    const accessToken = getAccessToken();
    
    // const accessToken = await getItem({ key: ACCESS_TOKEN });
    _config.headers.Authorization = accessToken
      ? `Bearer ${accessToken}`
      : undefined;
    return _config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async function (response) {
    return response;
  },
  function (error) {
    if (error.response?.status === 401) {
      // error.response?.data?.message
      setAccessToken();
      message.error(error?.response?.data?.message?? "Session timeout, please login again!");
      sleep(200).then(() => {
        redirectAfterDelay('/');
      });
    }
    return Promise.reject(error);
  }
);

export const getApiErrorMessage = ({ response }) =>
  response?.data?.data?.message?.message || response?.data?.data?.message;

export default instance;
