import React, { useEffect, useState } from "react";
import { Card, Table } from "antd";
import Filterbox from "../../ui/FilterBox/Filterbox";
import { serviceCategoriesColumn, servicesColumn } from "../../DummyAPI/TableData/Columns";
import { useDispatch, useSelector } from "react-redux";
import { listServices } from './../../redux/actions/service';
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { selectServicesData, selectServicesLoading } from './../../redux/selectors/serviceSelectors';

const AllServices = ({type}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(10);
  const isLoading = useSelector(selectServicesLoading)
  const serviceList = useSelector(selectServicesData);

  const tableStyle = {
    tableLayout: "fixed",
  };

  // function to load more or less table data
  const showMoreOrLessHandler = () => {
    setVisible((prevValue) => prevValue + 10);
    if (visible === 20) {
      setVisible((prevValue) => prevValue - prevValue + 10);
    }
  };

  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  useEffect(() => {
    dispatch(listServices({type}));
  }, [dispatch, type]);



  return (
    <ProgressLoader isLoading={isLoading}>
    <Card className="sapa-services__container">
      <Filterbox 
        filterType="Service" 
        searchIsLoading={isLoading}
        searchAction={listServices}
        searchActionData={{
          type
        }}
        applyFilterAction={listServices}
        applyFilterActionData={{
          type
        }}
      />

      <Table
        key={Math.random().toString()}
        dataSource={serviceList}
        columns={type?.toLowerCase() === "services"? serviceCategoriesColumn : servicesColumn}
        pagination={false}
        rowClassName={rowClassName}
        style={tableStyle}
      />
      <div className="sapa-services-table__showbtn_container">
          <button onClick={showMoreOrLessHandler}>
            {visible === 20 ? "Load Less" : "Load More"}
          </button>
        </div>
    </Card>
    </ProgressLoader>
  );
};

export default AllServices;
