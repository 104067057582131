export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const SHOULD_SHOW_MODAL = "SHOULD_SHOW_MODAL";

export const LOGIN = "auth/login";
export const LOGOUT = "auth/logout";
export const REFRESH_TOKEN = "auth/refreshToken";

export const SEND_OTP = "verification/sendOTP";
export const VERIFY_CODE = "verification/verifyCode";

