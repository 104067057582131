import { Space, Typography, Card, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import SapaHeader from "../../ui/Header/Header";
import Dashboard_LineChart from "./Dashboard-LineChart";
import DashboardCard from "./DashboardCard";
import { ChartData } from "../../DummyAPI/ChartData/ChartData";
import legend from "../../assets/media-icons/Group 1.png";
import calendar from "../../assets/svgs/Dashboard-icons/calendar.svg";
import { Menu, Dropdown } from "antd";
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectDashboardLoading } from "../../redux/selectors/dashboardSelectors";
import { fetchDashboardStatistics } from './../../redux/actions/dashboard';


const Dashboard = () => {
  const [userData, setuserData] = useState({
    labels: ChartData.map((data) => data.Month),
    datasets: [
      {
        label: "Profit",
        data: ChartData.map((data) => data.Profit),
        borderColor: "#318D1F",
      },
      {
        label: "Revenue",
        data: ChartData.map((data) => data.Revenue),
        borderColor: "#2F75FF",
      },
    ],
  });

  const dispatch = useDispatch();
  const [statistics, setStatistics] = useState(null);
  const isLoading = useSelector(selectDashboardLoading);


  const menu = (
    <Menu selectable className="sapa-dashboard__calendar_dropdown">
      <Menu.Item key={Math.random().toString()}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.alipay.com/"
        >
          All
        </a>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.taobao.com/"
        >
          Last Week
        </a>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.tmall.com/"
        >
          Last Month
        </a>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.tmall.com/"
        >
          Last 6 Months
        </a>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="http://www.tmall.com/"
        >
          Last Year
        </a>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    dispatch(fetchDashboardStatistics());
  }, [dispatch]);

  return (
    <ProgressLoader isLoading={isLoading}>
      <div className="Dashboard">
        <SapaHeader page="Dashboard" />
        <Space
          className="sapa-dashboard__topSection"
          direction="vertical"
          size={24}
        >
          <Typography.Text>Overview</Typography.Text>
          <div className="sapa-dashboard-card__container">
            <DashboardCard
              title="Total Income"
              value={`₦ ${statistics?.totalIncome || 0}`}
            />
            <DashboardCard
              title="Total Transactions"
              value={`${statistics?.totalTransactions || 0}`}
            />
            <DashboardCard
              title="Total Users"
              value={statistics?.totalUser || 0}
            />
            <DashboardCard
              title="Profit Margin"
              value={`₦ ${statistics?.totalProfitMargin || 0}`}
            />
          </div>
        </Space>
        <Space
          className="sapa-dashboard__topSection"
          direction="vertical"
          size={24}
        >
          <Typography.Text>Income Flow</Typography.Text>
          <Card className="sapa-dashboard__lowerSection">
            <div className="sapa-dashboard__legend_container">
              <img className="chart-img" src={legend} alt="/" />
              <Dropdown overlay={menu}>
                <a className="ant-dropdown-link" href="#">
                  <img className="calendar-icon" src={calendar} alt="/" />
                </a>
              </Dropdown>
            </div>
            <Dashboard_LineChart chartData={userData} />
          </Card>
        </Space>
      </div>
    </ProgressLoader>
  );
};

export default Dashboard;
