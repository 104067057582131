import React, {useState} from 'react'
import SapaHeader from '../../ui/Header/Header'
import AllServices from './AllServices';
import QueryLogs from './QueryLogs';
import { useLocation } from 'react-router-dom';


const Services = () => {
  const [activeTab, setActiveTab] = useState(true);
  const location = useLocation();

  const paths = location.pathname.split('/');
  const lastSegment = (paths.filter(Boolean).pop())?.toLowerCase();

  return (
    <div className='Services'>
      <SapaHeader page={lastSegment} showArrow={paths?.length > 3} />
     {/*  <div className='sapa-services__tab'>
         <button className={activeTab ? 'active services-tab__all-services-btn' : 'services-tab__all-services-btn'} onClick={() => setActiveTab(true)}  >
          All services
         </button>
         <button className={!activeTab ? 'active services-tab__query-logs-btn' : 'services-tab__query-logs-btn'} onClick={() => setActiveTab(false)} >
          Query Logs
         </button>
      </div> */}
      {activeTab ? (<AllServices type = {lastSegment}  />) : (<QueryLogs />) }
    </div>
  )
}

export default Services