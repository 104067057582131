import { createPromiseAction } from '@adobe/redux-saga-promise';

// Login Actions
export const login = createPromiseAction('auth/login');

// Refresh Token Actions
export const refreshToken = createPromiseAction('auth/refreshToken');

// Refresh Token Actions
export const logout = createPromiseAction('auth/logout');
