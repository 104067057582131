import { createReducer } from '@reduxjs/toolkit';
import {
  listServices,
  filterServices,
  searchServices,
  exportServices,
} from '../actions/service';

const initialState = {
  services: {
    data: [],            // List of services (includes filtered or search results)
    currentPage: 1,      // Current page for pagination
    totalPages: 0,       // Total number of pages
    pageSize: 10,        // Items per page
  },
  loading: false,        // Loading state for listing, filtering, or searching services
  error: null,           // General error state

  // Export state as an object
  export: {
    loading: false,      // Loading state for export action
    success: false,      // Tracks if export was successful
    error: null,         // Export-specific error
  },
};

const servicesReducer = createReducer(initialState, {
  // Listing services (with pagination)
  [listServices.trigger]: (state) => {
    state.loading = true;
    state.error = null;
    state.services = {};
  },
  [listServices.resolved]: (state, action) => {
    const { data: services, currentPage, totalPages, pageSize } = action.payload;
    state.loading = false;
    state.services = {
      data: services,    // Set fetched services data
      currentPage,       // Update current page from the backend
      totalPages,        // Update total pages from the backend
      pageSize,          // Update page size (if provided by backend)
    };
  },
  [listServices.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle listing error
  },

  // Filter services (handled in the backend)
  [filterServices.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [filterServices.resolved]: (state, action) => {
    const { services, currentPage, totalPages, pageSize } = action.payload;
    state.loading = false;
    state.services = {
      data: services,    // Set filtered services data
      currentPage,       // Update pagination after filtering
      totalPages,
      pageSize,
    };
  },
  [filterServices.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle filtering error
  },

  // Search services (handled in the backend)
  [searchServices.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [searchServices.resolved]: (state, action) => {
    const { services, currentPage, totalPages, pageSize } = action.payload;
    state.loading = false;
    state.services = {
      data: services,    // Set search results
      currentPage,       // Update pagination after search
      totalPages,
      pageSize,
    };
  },
  [searchServices.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle search error
  },

  // Export services (managed as a separate object)
  [exportServices.trigger]: (state) => {
    state.export.loading = true;  // Set export-specific loading
    state.export.success = false; // Reset success status
    state.export.error = null;    // Clear export errors
  },
  [exportServices.resolved]: (state) => {
    state.export.loading = false; // Stop export loading
    state.export.success = true;  // Set export success flag
  },
  [exportServices.rejected]: (state, action) => {
    state.export.loading = false; // Stop export loading
    state.export.success = false; // Mark export as unsuccessful
    state.export.error = action.payload; // Handle export error
  },
});

export default servicesReducer;
