import React, { createContext, useState } from "react";

export const SapaContext = createContext();

export const SapaContextProvider = ({ children }) => {
  const [showOverview, setShowOverview] = useState(false);
  const [open, setOpen] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showCommission, setShowCommission] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  return (
    <SapaContext.Provider
      value={{
        showOverview,
        setShowOverview,
        open,
        setOpen,
        showTransactions,
        setShowTransactions,
        showCommission,
        setShowCommission,
        activeTab,
        setActiveTab,
      }}
    >
      {children}
    </SapaContext.Provider>
  );
};
