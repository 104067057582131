import React from "react";
import { Statistic, Card, Avatar } from "antd";
import infoCircle from "../../assets/svgs/Dashboard-icons/Info-Circle.svg";
const DashboardCard = ({ title, value, showButton }) => {
  return (
    <div className="dashboard-card__container">
      <Card>
        <div className="dashboard-card__container_inner">
          <Statistic
            className="dashboard-card__topSection"
            title={title}
            value={value}
          />
          <div className="dashboard-card__btn-container">
            <Avatar
              className="dashboard-card__topSection_icon"
              src={infoCircle}
            ></Avatar>
            {showButton && <button className="edit-button">Edit</button>}
          </div>
        </div>
      </Card>
    </div>
  );
};

DashboardCard.defaultProps = {
  showButton: false,
};

export default DashboardCard;
