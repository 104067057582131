// Selector to get the entire auth state
export const selectAuthState = (state) => state.auth;

// Selector to get the loading status
export const selectAuthLoading = (state) => state.auth.loading;

// Selector to get the auth error
export const selectAuthError = (state) => state.auth.error;

// Selector to get the user data (tokens, user details)
export const selectAuthData = (state) => state.auth.data;

// Selector to get the access token
export const selectAccessToken = (state) => state.auth.data?.access_token;


