import dashboardIcon from "../../assets/svgs/menu-icons/DashboardIcon";
import transactionIcon from "../../assets/svgs/menu-icons/TransactionIcon";
import servicesIcon from "../../assets/svgs/menu-icons/ServicesIcon";
import apiprovidersIcon from "../../assets/svgs/menu-icons/APIproviderIcon";
import usersIcon from "../../assets/svgs/menu-icons/UsersIcon";
/* import alertsIcon from "../../assets/svgs/menu-icons/AlertsIcon";
import administratorsIcon from "../../assets/svgs/menu-icons/AdministratorsIcon";
import securityIcon from "../../assets/svgs/menu-icons/SecurityIcon";
 */
export const MENU_KEYS = {
  DASHBOARD: "overview",
  TRANSACTIONS: "transactions",
  SERVICES: "services",
  APIPROVIDERS: "api-providers",
  USERS: "users",
/*   Alerts: "Alerts",
  Administrators: "Administrators",
  Security: "Security", */
};

export const dashboardMenus = (selected) => {

  const _buildIcon = (key, menuIcon) => {
    return menuIcon({ fill: key?.toLowerCase() === selected?.toLowerCase() ? "#9747ff" : undefined });
  };

  const {
    DASHBOARD,
    TRANSACTIONS,
    SERVICES,
    APIPROVIDERS,
    USERS,
   /*  Alerts,
    Administrators,
    Security, */
  } = MENU_KEYS;


  return [
    {
      key: DASHBOARD,
      label: "Dashboard",
      icon: _buildIcon(DASHBOARD, dashboardIcon),
      children: null,
    },
    {
      key: TRANSACTIONS,
      label: "Transactions",
      icon: _buildIcon(TRANSACTIONS, transactionIcon),
      children: null,
    },
    {
      key: SERVICES,
      label: "Services",
      icon: _buildIcon(SERVICES, servicesIcon),
      children: null,
    },
    {
      key: APIPROVIDERS,
      label: "API Providers",
      icon: _buildIcon(APIPROVIDERS, apiprovidersIcon),
      children: null,
    },
    {
      key: USERS,
      label: "Users",
      icon: _buildIcon(USERS, usersIcon),
      children: null,
    },
 /*    {
      key: Alerts,
      label: "Alerts",
      icon: _buildIcon(Alerts, alertsIcon),
      children: null,
    },
    {
      key: Administrators,
      label: "Administrators",
      icon: _buildIcon(Administrators, administratorsIcon),
      children: null,
    },
    {
      key: Security,
      label: "Security",
      icon: _buildIcon(Security, securityIcon),
      children: null,
    }, */
  ];
};
