import { Card, Table } from "antd";
import React, { useEffect, useState } from "react";
import SapaHeader from "../../ui/Header/Header";
import Filterbox from "../../ui/FilterBox/Filterbox";
import { columns } from "../../DummyAPI/TableData/Columns";
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { useDispatch, useSelector } from "react-redux";
import { selectTransactionList, selectTransactionsLoading } from './../../redux/selectors/transactionSelectors';
import { listTransactions } from "../../redux/actions/transaction";

const Transactions = ({ showHeader, type }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(10);
  
  const transactionList = useSelector(selectTransactionList);
  const isLoading = useSelector(selectTransactionsLoading);

  const tableStyle = {
    tableLayout: "fixed",
  };

 
  // function to load more or less table data
  const showMoreOrLessHandler = () => {
    if (visible === 10) {
      setVisible(transactionList.length);
    } else {
      setVisible(10);
    }
  };

  // adding classname to odd table rows
  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  const columnKeys = columns.map((column) => column.key);

  useEffect(() => {
    dispatch(listTransactions());
  }, [dispatch]);

  return (
    <ProgressLoader isLoading={isLoading}>
      <div className="Transactions">
      {showHeader && <SapaHeader page={"Transactions"} />}
      <Card className="sapa-transactions__container">
        <Filterbox 
          type={type} 
          searchAction={listTransactions}
          searchIsLoading={isLoading}
          applyFilterAction={listTransactions}
        />
        <Table
          key={columnKeys}
          dataSource={transactionList.slice(0, visible)} 
          columns={columns}
          pagination={false}
          rowClassName={rowClassName}
          style={tableStyle}
        />
        <div className="sapa-transactions-table__showbtn_container">
          <button onClick={showMoreOrLessHandler}>
            {visible === 20 ? "Load Less" : "Load More"}
          </button>
        </div>
      </Card>
    </div>
    </ProgressLoader>
  );
};

Transactions.defaultProps = {
  showHeader: true,
};

export default Transactions;
